<template>
  <van-dialog
    v-model:show="showVerifyDialog"
    title="验证码"
    @confirm="dialogConfirm"
    show-cancel-button
  >
    <van-cell-group inset>
      <van-field
        v-model="pic.picCode"
        center
        label-width="4em"
        name="picCode"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <img @click="getPicImageHandle" class="code-img" :src="pic.codeImg" />
        </template>
      </van-field>
    </van-cell-group>
  </van-dialog>
</template>
<script setup>
import { reactive, watchEffect, computed, defineEmits, defineProps } from "vue";
import { getTestSMS, sendSMS, getPicImage } from "@/api/user";
import { showFailToast } from "vant";
import { util, isEmpty } from "@/common/validate";

const props = defineProps({
  show: Boolean,
  phone: String,
  type: Number,
});

const emits = defineEmits(["confirm", "update:show"]);

const showVerifyDialog = computed({
  get() {
    return props.show;
  },
  set(val) {
    emits("update:show", val);
  },
});

watchEffect(() => {
  if (showVerifyDialog.value) {
    getPicImageHandle();
    pic.picCode = "";
  }
});

const pic = reactive({
  codeImg: "",
  picCode: "",
  picUUID: "",
});

// 校验手机号
const validatorPhone = (val) => util.isPhone(val);

const getPicImageHandle = function () {
  // 获取图片验证码
  getPicImage().then((res) => {
    const data = res.data || {};
    pic.picUUID = data.uuid;
    pic.codeImg = data.img;
  });
};

const getSMSHandle = function () {
  if (!validatorPhone(props.phone)) return showFailToast("请填写正确的手机号");
  if (process.env.VUE_APP_FLAG === "production") {
    sendSMS({
      phone: props.phone,
      picCode: pic.picCode,
      picUUID: pic.picUUID,
      type: props.type,
    }).then((res) => {
      emits("confirm", { type: "production", data: { uuid: res.data || "" } });
    });
  } else {
    getTestSMS({ phone: props.phone }).then((res) => {
      emits("confirm", { type: "test", data: res.data || {} });
    });
  }
};

const dialogConfirm = function () {
  if (isEmpty(pic.picCode)) return showFailToast("请填写正确的验证码");
  getSMSHandle();
};
</script>
<style scoped>
.code-img {
  width: 5em;
  object-fit: contain;
}
</style>
