<template>
  <div class="me-product-content">
    <!-- <van-pull-refresh success-text="刷新成功" v-model="refreshing" @refresh="onRefresh"> -->
    <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div v-for="item in list" :key="item.id" class="foster-content-item">
        <div class="orderno-status-box">
          <div class="orderno-box">
            数编号：{{ item.number }}
          </div>
          <div class="status-box">{{ item.status == 1 ? '认养中' : '认养完成' }}</div>
        </div>
        <div class="foster-base-info-box">
          <div class="img-box">
            <img :src="item.picture">
          </div>
          <div class="other-info-box">
            <div class="earnings-detail-box">
              <!-- <div class="detail-item-box">
                <span v-if="item.incomeVO">{{ item.incomeVO.levelIncome.toFixed(2) }}</span>
                <span>今日级差收入</span>
              </div> -->
              <div class="detail-item-box">
                <span class="earnings-color" v-if="item.incomeVO">+{{ item.incomeVO.income.toFixed(2) }}</span>
                <span>今日收益</span>
              </div>
              <div class="detail-item-box">
                <span>{{ item.growMax.toFixed(2) }}</span>
                <span>预计总收入</span>
              </div>
            </div>
            <div class="growth-progress">
              <van-progress class="progress-item" :percentage="(item.totalIncome / item.growMax * 100) || 0
              " stroke-width="9" color="#FF6700" :show-pivot="false" />
              <span class="progress-right-text">{{ (item.totalIncome / item.growMax * 100).toFixed(2) }}%</span>
            </div>
            <div class="clac-earnings"><span>累计收益</span><span>{{ item.totalIncome.toFixed(2) }}</span></div>
          </div>
        </div>
      </div>
    </van-list>
    <!-- </van-pull-refresh> -->
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { getOwnProductList } from "@/api/fosterProduct";
import { formatData } from "@/common/tiem";
const props = defineProps({
  active: String,
});

const emits = defineEmits(["onRefresh"]);

// 当前页
const curPage = ref(1);
// 分页大小
const pageSize = ref(10);
const total = ref(0);
// 数据列表
const list = ref([]);
// 加载状态
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);

const onLoad = function () {
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;

  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }

  getOwnProductList({
    pageNo: curPage.value,
    pageSize: pageSize.value,
    status: props.active,
  }).then((res) => {
    console.log(res.data);
    const data = res.data || {};
    loading.value = false;
    total.value = data.total;
    list.value.push(...data.list);
    if (list.value.length === data.total) {
      finished.value = true;
    } else {
      curPage.value++;
    }
  });
};

const onRefresh = function () {
  // 清空列表数据
  curPage.value = 1;
  finished.value = false;
  onLoad();
  emits("onRefresh");
};
</script>
<style lang="scss" scoped>
.me-product-content {
  height: calc(100vh - 605px);
  margin-top: 20px;
  // .van-list {
  //   height: calc(100vh - 605px);
  // }

  .foster-content-item {
    width: 712px;
    // height: 291px;
    border-radius: 10px;
    background: #FFFFFF;
    margin: 0px auto 19px auto;
    box-sizing: border-box;
    padding: 0px 24px 24px 24px;

    .orderno-status-box {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      font-size: 28px;
      border-bottom: 1px dashed #D4D4D4;

      .orderno-box {
        color: #131417;
      }

      .status-box {
        color: #06B577;
      }
    }

    .foster-base-info-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      .img-box {
        width: 154px;
        height: 151px;
        margin-right: 23px;

        & img {
          width: 100%;
          height: 100%;
        }
      }

      .other-info-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .earnings-detail-box {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .detail-item-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #979797;
            font-family: PingFangSC-Regular;
            font-size: 22px;
            font-weight: normal;
            box-sizing: border-box;
            padding: 20px 0px;

            & span:nth-child(1) {
              color: #121924;
              margin-bottom: 4px;
            }

            & span:nth-child(2) {
              color: #57575A;
            }

            & span.earnings-color {
              color: #FF6700;
            }
          }

          .detail-item-box:last-child {
            border-right: none;
          }
        }

        .growth-progress {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 15px 0px 10px 0px;

          .progress-item {
            flex: 1;
          }

          .progress-right-text {
            font-family: HelveticaNeue;
            font-size: 20px;
            font-weight: normal;
            color: #57575A;
            margin-left: 13px;
          }
        }

        .clac-earnings {
          font-family: PingFangSC-Regular;
          font-size: 28px;
          font-weight: normal;
          color: #121924;
          display: flex;
          flex-direction: row;
          align-items: center;

          & span:nth-child(1) {
            font-size: 28px;
            margin-right: 8px;
          }

          & span:nth-child(2) {
            font-size: 42px;
          }
        }
      }
    }
  }
}
</style>
