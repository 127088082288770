<template>
  <div class="login">
    <div class="login-main">
      <van-icon name="wap-home-o" size="3.5rem" style="margin-left: 1vh" @click="() => router.push({ path: '/home' })"/>
      <div class="login-title">登录乡愁生态农场</div>
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            v-model="formInfo.username"
            name="username"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[
              { required: true, message: '请填写手机号' },
              {
                validator: validatorPhone,
                message: '请输入正确手机号码',
              },
            ]"
          />
          <van-field
            v-model="formInfo.password"
            type="password"
            name="password"
            label="密码"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />

          <van-field
            v-model="formInfo.code"
            center
            name="code"
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #button>
              <img @click="getPicImageHandle" class="code-img" :src="codeImg" />
            </template>
          </van-field>
        </van-cell-group>

        <van-cell-group class="rm-bottom-border">
          <van-cell :border="false">
            <template #value>
              <van-button
                @click="toUpdatePassword"
                plain
                hairline
                type="primary"
                size="small"
              >
                忘记密码
              </van-button>
              <van-checkbox v-model="checked" style="margin-bottom: -20px">
                <p style="color: black; width: 100%; font-size: 25px;">
                  <span style="color: #A3A3A3;">已阅读并同意以下协议：</span>
                  <a @click="() => router.push({ path: '/privacy' })" style="color: black">《 隐私权政策 》</a>
                </p>
              </van-checkbox>
            </template>
          </van-cell>
        </van-cell-group>

        <div style="margin: 16px">
          <van-button
            :loading="loginLoading"
            loading-text="登录中..."
            round
            block
            type="primary"
            native-type="submit"
          >
            登录
          </van-button>
        </div>

        <div style="margin: 16px">
          <van-button @click="toRegisterPage" plain type="primary" round block>
            注册
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button color="linear-gradient(to right, #ff6034, #d32f43)" @click="() => router.push({ path: '/home' })"  type="primary" round block>
            返回首页
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, toRaw } from "vue";
import { userLogin, getPicImage, getUserInfo } from "@/api/user";
import { useRouter } from "vue-router";
import { openAdminRouter } from "@/router/index";
import { cleanKeepAlive } from "@/hooks/keepAliveRouter";
import { setToken, setUserInfo, removeUserInfo } from "@/api/token";
import { util } from "@/common/validate";

// 缓存页面
// const cancelKeepAlive = useKeepAlive("LoginView");
removeUserInfo(); // 清除用户信息
cleanKeepAlive(); // 清除缓存
const formInfo = reactive({
  username: "",
  password: "",
  code: "",
  uuid: "",
});
const checked = ref(true);
const codeImg = ref("");
const loginLoading = ref(false);
const router = useRouter();
// 校验手机号
const validatorPhone = (val) => util.isPhone(val) || true;
const toRegisterPage = function () {
  router.replace("/register");
};
const getPicImageHandle = function () {
  // 获取图片验证码
  getPicImage().then((res) => {
    const data = res.data || {};
    formInfo.uuid = data.uuid;
    codeImg.value = data.img;
    formInfo.code = "";
  });
};

function onSubmit() {
  loginLoading.value = true;
  userLogin(toRaw(formInfo))
    .then((res) => {
      const data = res.data || {};
      // cancelKeepAlive();
      setToken(data.token);
      return getUserInfo();
    })
    .then((res) => {
      const data = res.data || {};
      setUserInfo(data);
      if (data.financeAdmin) {
        openAdminRouter();
      }
      router.push("/home");
    })
    .catch(() => {
      getPicImageHandle();
    })
    .finally(() => {
      loginLoading.value = false;
    });
}

const toUpdatePassword = function () {
  router.push("/updatePassword");
};

getPicImageHandle();
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  background-color: var(--van-blue);
  overflow: hidden;

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--van-blue);
    font-size: 32px;
    margin: 10px 0 20px 0;
  }

  &-main {
    width: 94%;
    padding: 2vh 8px;
    margin: 10vh auto auto auto;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 5px 5px -5px #dcdcdc;
    background-color: var(--van-white);

    &-top {
      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 5px;
        font-size: 28px;
      }
    }

    &-bottom {
      width: 80%;
      margin-top: 6vh;
    }

    &-flex {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 4vh;
    }
  }

  .code-img {
    width: 5em;
    object-fit: contain;
  }

  .rm-bottom-border::after {
    border-bottom: 0px;
  }
}
</style>
