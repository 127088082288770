import { openAdminRouter, isWhite } from "@/router/index";
import { getUserInfo, getToken } from "@/api/token";

// 防止刷新页面丢失数据
export const initData = async function () {
  // 白名单内，或者没有token，不执行动态路由逻辑
  if (isWhite || !getToken()) return;
  const userInfo = await getUserInfo();
  // 防止刷新页面丢失路由
  if (userInfo.financeAdmin) {
    openAdminRouter();
  }
};
