import { http } from "@/api/request.js";

/**
 * 获取首页商城商品列表
 * @param {Object} params
 * @returns
 */
export const getShopProducts = function (params) {
  return http.get("/sqsm/product/pub/page", { params });
};

/**
 * 获取首页商城商品列表
 * @param {Object} params
 * @returns
 */
export const getShopProductDetail = function (params) {
  return http.get("/sqsm/product/pub/get", { params });
};

/**
 * 获取活动列表
 * @param {Object} params
 * @returns
 */
export const getActivityProducts = function (params) {
  return http.get("/sqsm/product-activity/page", { params });
};
/**
 * 获取活动关联的商品列表
 * @param {Object} params
 * @returns
 */
export const getProductsByActivity = function (params) {
  return http.get("/sqsm/product-activity-rel/page", { params });
};

/**
 * 获取活动关联的商品
 * @param {Object} params
 * @returns
 */
export const getActivityProduct = function (params) {
  return http.get("/sqsm/product-activity-rel/get", { params });
};

/**
 * 获取商品分享海报
 * @param {Object} params
 * @returns
 */
export const getShareProductPoster = function (params) {
  return http.post("/sqsm/product/generate-image", params);
};

/**
 * 获取客服信息
 * @param
 * @returns
 */
export const getCustomerService = function () {
  return http.get("/pomelo/index/getContact");
};
