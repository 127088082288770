<template>
  <div class="my-foster-product">
    <van-nav-bar fixed placeholder :title="'我的认养'"/>
    <div class="my-foster-info">
      <div class="content-box">
        <div class="title-box">
          <img src="../assets/images/foster/icon_foster.png">
          <span>认养收入（积分）</span>
        </div>
        <div class="money-box">
          <span>{{ totalIncome }}</span>
        </div>
        <div class="calc-num-box">
          <span>今日累计 {{ todayIncome }}</span>
          <span>认养数量 {{ proNum }}</span>
        </div>
      </div>
    </div>
    <van-tabs v-model:active="active" sticky :offset-top="'393px'">
      <van-tab name="1" title="认养中">
        <OwnProductList v-if="active === '1'" active="1" @onRefresh="onRefresh"/>
      </van-tab>
      <van-tab name="2" title="认养完成">
        <OwnProductList v-if="active === '2'" active="2" @onRefresh="onRefresh"/>
      </van-tab>
      <!-- <van-tab name="-1" title="终止成长">
        <OwnProductList active="-1" @onRefresh="onRefresh" />
      </van-tab> -->
    </van-tabs>
  </div>
</template>
<script setup>
import {ref, reactive, onBeforeMount} from "vue";
import Big from "big.js";
import {useKeepAlive} from "@/hooks/keepAliveRouter";
import {getMyProNum} from "@/api/fosterProduct";
import {
  getTodayIncomeTotal,
  getUserIncomeTotal,
  getTodayIncomeInfo,
  getShareUserCount
} from "@/api/earnings";
import OwnProductList from "@/components/OwnProductList.vue";

// 缓存页面
// useKeepAlive("MyFosterProduct");
// tab 1：成长中，2：成长完成，-1：终止成长
const active = ref("1");
// 柚子树总数
const proNum = ref(0);
let todayIncome = ref(0); // 今日收益
let totalIncome = ref(0); // 累计总收入
let shareUserCountData = reactive({
  personNum: 0,
  todayNum: 0
})

const getMyProNumHandle = function () {
  getMyProNum().then((res) => {
    proNum.value = res.data;
  });
};

const onRefresh = function () {
  getMyProNumHandle();
};

onBeforeMount(() => {
  getMyProNumHandle();
  getTodayIncomeTotal().then((res) => {
    todayIncome.value = res.data;
  });

  getUserIncomeTotal().then((res) => {
    const data = res.data || {};
    totalIncome.value = Big(data.income)
        .plus(data.levIncome)
        .plus(data.subIncome);
  });
  getShareUserCount({}).then((res) => {
    shareUserCountData.personNum = res.data.personNum
    shareUserCountData.todayNum = res.data.todayNum
  })
});

</script>

<style lang="scss" scoped>
.my-foster-product {
  min-height: calc(100vh - 100px);
  position: relative;
  background: #F5F6F8;

  .my-foster-info {
    width: 750px;
    height: 300px;
    position: sticky;
    top: 92px;
    z-index: 9;
    background-color: #FFFFFF;
    // padding-bottom: 30px;

    .content-box {
      width: 690px;
      margin: 0px 30px 0px 30px;
      box-sizing: border-box;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 33px 55px;
      border-radius: 0px 0px 30px 30px;
      background: linear-gradient(270deg, #71DCB6 0%, #00B374 101%);
    }

    .title-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      & img {
        width: 45px;
        height: 47px;
        margin-right: 14px;
      }

      & span {
        font-family: PingFangSC-Regular;
        font-size: 32px;
        font-weight: normal;
        color: #FFFFFF;
      }
    }

    .money-box {
      font-family: PingFangSC-Semibold;
      font-size: 60px;
      font-weight: normal;
      color: #FFFFFF;
    }

    .calc-num-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 28px;
      font-weight: normal;
      color: #FFFFFF;
    }
  }

  &-content {
    margin-top: 8px;
  }

  &-img {
    display: block;
    width: 96%;
    margin: auto;
    margin-top: 8px;
  }
}
</style>
