<template>
  <div class="home-container">
    <van-nav-bar fixed placeholder title="乡愁生态农场" />
    <div class="search-box">
      <van-search
        readonly
        fixed
        shape="round"
        placeholder="请输入搜索关键词"
        @focus="() => router.push({ name: 'shoppingSearchProducts' })"
      />
    </div>
    <!-- 广告轮播图开始 -->
    <div class="advSwipe">
      <!-- :width="700" -->
      <van-swipe v-if="bannerList.length > 0" autoplay="5000" :loop="true">
        <van-swipe-item v-for="item in bannerList" :key="item.image">
          <van-image fit="contain" class="swipe-img" :src="item.image" />
        </van-swipe-item>
      </van-swipe>
      <!-- <swiper :slides-per-view="1.1" :looped-slides="bannerList.length" :slide-to-clicked-slide="false" :centered-slides="true" :autoplay="true" :space-between="10" :loop="true" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="item in bannerList" :key="item.image">
           <img class="swipe-img" :src="item.image">
        </swiper-slide>
      </swiper> -->
    </div>
    <!-- 广告轮播图结束 -->

    <van-skeleton :loading="refreshing">
      <template #template>
        <div class="skeleton-content-box">
          <van-skeleton-image v-if="bannerList.length <= 0" />
          <div class="skeleton-text-box" v-if="noticeList.length <= 0">
            <van-skeleton-paragraph row-width="60%" />
          </div>
          <template v-if="seckillProductList.length <= 0">
            <div v-for="item in 2" :key="item">
              <div class="skeleton-text-box">
                <van-skeleton-paragraph row-width="40%" />
                <van-skeleton-paragraph row-width="20%" />
              </div>
              <div class="product-not-shopping-list" style="margin-top: 10px">
                <div class="product-not-shopping-item">
                  <van-skeleton-image />
                  <van-skeleton-paragraph row-width="80%" />
                  <van-skeleton-paragraph row-width="60%" />
                </div>
                <div class="product-not-shopping-item">
                  <van-skeleton-image />
                  <van-skeleton-paragraph row-width="80%" />
                  <van-skeleton-paragraph row-width="60%" />
                </div>
                <div class="product-not-shopping-item">
                  <van-skeleton-image />
                  <van-skeleton-paragraph row-width="80%" />
                  <van-skeleton-paragraph row-width="60%" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </van-skeleton>
    <!-- 消息通知 -->
    <van-notice-bar
      v-if="noticeList.length > 0"
      class="notice-bar-box"
      left-icon="volume-o"
      :scrollable="true"
      :delay="5"
      background="#FFF9ED"
      @replay="changeNoticeContent"
    >
      {{ noticeList[noticeIndex].text }}
    </van-notice-bar>
    <van-pull-refresh
      success-text="刷新成功"
      v-model="refreshing"
      @refresh="onRefresh"
    >
      <!--    认养农产品开始-->
      <div class="product-type-item" v-if="fosterProductList.length > 0">
        <div class="title-box">
          <span class="title">认养农产品</span>
          <span class="more-btn" @click="moreFosterProducts">查看更多></span>
        </div>
        <div class="product-not-shopping-list">
          <div
            class="product-not-shopping-item"
            @click="goFosterProductDetail(item.id)"
            v-for="item in fosterProductList"
            :key="item.id"
          >
            <img class="pic" :src="item.picture" alt="商品图片" />
            <span class="name line-1">{{ item.name }}</span>
            <product-price
              :price="item.price.toFixed(2)"
              symbol-color="red"
              symbolSize="20px"
              money-size="28px"
            ></product-price>
            <!--          <span class="price">￥{{ item.price.toFixed(2) || 0.0 }}</span>-->
            <!-- <span class="price">
            ￥
            <span class="price-main">{{ item.price }}</span>
            .00
          </span> -->
          </div>
        </div>
      </div>
      <!--    抽奖开始-->
      <div
        class="lottry-entry-box"
        v-if="homeDrawInfo && homeDrawInfo.isShow"
        @click="() => router.push({ path: '/foster/luckyDraw' })"
      >
        <img :src="homeDrawInfo.url" alt="抽奖入口图片" />
      </div>
      <!--    抽奖结束-->
      <!--    认养农产品结束-->

      <div class="product-type-item" v-if="seckillProductList.length > 0">
        <div class="title-box">
          <span class="title">秒杀活动</span>
          <span
            class="more-btn"
            @click="() => router.push({ path: '/shopping/seckillProducts' })"
            >查看更多></span
          >
        </div>
        <div class="product-not-shopping-list">
          <div
            class="product-not-shopping-item"
            @click="goSeckillProductDetail(item)"
            v-for="item in seckillProductList"
            :key="item.id"
          >
            <img
              class="pic seckill-pic"
              :src="item.product ? item.product.pic : ''"
            />
            <div class="seckill-pic-under">
              <div class="seckill-pic-under-describe">
                <span>秒杀价</span>
              </div>
              <product-price
                :price="item.activityPrice.toFixed(2)"
                symbol-color="#fff"
                money-color="#fff"
                symbolSize="20px"
                money-size="28px"
              ></product-price>
            </div>
            <div class="seckill-name line-1">
              <span>{{ item.productName }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 推荐商品列表 -->
      <div class="recommend-title-box" v-if="recommendProductList.length > 0">
        <span>—— 推荐商品 ——</span>
      </div>
      <van-list
        v-model:loading="recommendSearch.loading"
        :finished="recommendSearch.finished"
        @load="getRecommendProductList"
      >
        <div class="product-shopping-list">
          <shopping-product-item
            v-for="product in recommendProductList"
            :key="product.id"
            :productInfo="product"
            @click="goShoppingProductDetail(product.id)"
          >
          </shopping-product-item>
        </div>
        <template #finished>
          <span @click="() => router.push({ name: 'shoppingSearchProducts' })"
            >点击查看更多>></span
          >
        </template>
      </van-list>
    </van-pull-refresh>

    <van-dialog
      v-if="
        !showDialogFinish && dialogNoticeList && dialogNoticeList.length > 0
      "
      v-model:show="showNoticeDialog"
      :title="dialogNoticeList[dialogNoticeIndex].name"
      transition="ease-out"
      @closed="changeNextNotice"
    >
      <div
        class="notive-content-box"
        v-html="dialogNoticeList[dialogNoticeIndex].text"
      ></div>
    </van-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import shoppingProductItem from "@/components/ShoppingProductItem.vue";
import { useKeepAlive } from "@/hooks/keepAliveRouter";
import { getFosterProducts, getNotFosterProducts } from "@/api/fosterProduct";
import { getBannerList, getHomeDrawInfo, getNoticeList } from "@/api/inform";
import { getProductsByActivity, getShopProducts } from "@/api/mall/product";
import { showToast } from "vant";
import productPrice from "@/components/ProductPrice.vue";
import Cookies from "js-cookie";
// Import Swiper Vue.js components
// import { Autoplay } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/bundle";
// 缓存页面
useKeepAlive("Home");
const router = useRouter();

// 加载状态
const refreshing = ref(false);
// 认养农产品数据列表
let fosterProductList = ref([]);
// 助农产品数据列表
let helpProductList = ref([]);
// 寄售农产品数据列表
let agencyProductList = ref([]);

let seckillProductList = ref([]);

// 推荐商品列表
let recommendProductList = ref([]);

let bannerList = ref([]);
let noticeList = ref([]);
let noticeIndex = ref(0);

// 公告
const showNoticeDialog = ref(false);
let dialogNoticeList = ref([]);
let dialogNoticeIndex = ref(0);
let showDialogFinish = ref(false);

let homeDrawInfo = reactive({
  url: "",
  isShow: false,
});

let recommendSearch = reactive({
  params: {
    pageNo: 1,
    pageSize: 10,
    recommendStatus: 1,
  },
  loading: false,
  finished: false,
});

const goFosterProductDetail = (id) => {
  router.push({ path: "/foster/productDetail", query: { id } });
};

const goShoppingProductDetail = (id) => {
  router.push({ path: "/shopping/productDetail", query: { id } });
};

const goSeckillProductDetail = (item) => {
  let curTime = new Date().getTime();
  if (curTime <= item.endTime) {
    router.push({
      path: "/shopping/productDetail",
      query: {
        id: item.productId,
        seckillProductId: item.id,
        activityType: "seckill",
      },
    });
  } else {
    showToast({ message: "秒杀商品已过期" });
  }
};

// 获取产品列表
const getFosterProductList = () => {
  return getFosterProducts({
    pageNo: 1,
    pageSize: 3,
    productCategoryId: 1,
  })
    .then((res) => {
      const data = res.data || {};
      fosterProductList.value = data.list || [];
    })
    .finally(() => {
      // refreshing.value = false;
    });
};

const moreFosterProducts = () => {
  router.push({ path: "/foster/fosterProducts" });
};

// 获取助农产品列表
const getHelpProductList = () => {
  return getNotFosterProducts({
    pageNo: 1,
    pageSize: 3,
    productCategoryId: 2,
  })
    .then((res) => {
      const data = res.data || {};
      helpProductList.value = data.list || [];
    })
    .finally(() => {
      // refreshing.value = false;
    });
};

// 获取寄售产品列表
const getAgencyProductList = () => {
  return getNotFosterProducts({
    pageNo: 1,
    pageSize: 3,
    productCategoryId: 3,
  }).then((res) => {
    const data = res.data || {};
    agencyProductList.value = data.list || [];
  });
};

// 获取秒杀活动商品列表
const getActivityProductList = () => {
  return getProductsByActivity({
    pageNo: 1,
    pageSize: 3,
    productActivityId: 1,
  })
    .then((res) => {
      if (res.data) {
        seckillProductList.value = res.data.list || [];
        // seckillProductList.value = seckillProductList.value.filter((i) => {
        //   return new Date().getTime() > i.beginTime
        // })
      }
    })
    .finally(() => {
      refreshing.value = false;
    });
};

// 获取推荐商品列表
const getRecommendProductList = async () => {
  recommendSearch.loading = true;
  await getShopProducts(recommendSearch.params)
    .then((res) => {
      if (res.data && res.data.list.length > 0) {
        let list = res.data.list || [];
        list.forEach((item) => {
          recommendProductList.value.push(item);
        });
        if (
          recommendProductList.value.length === res.data.total ||
          recommendSearch.params.pageNo >= 3
        ) {
          recommendSearch.finished = true;
        } else {
          recommendSearch.params.pageNo++;
        }
      }
    })
    .finally(() => {
      recommendSearch.loading = false;
    });
};

const onRefresh = async () => {
  // 清空列表数据
  refreshing.value = true;
  fosterProductList.value = [];
  // helpProductList.value = [];
  // agencyProductList.value = [];
  seckillProductList.value = [];
  recommendProductList.value = [];
  await getFosterProductList();
  // await getHelpProductList();
  // await getAgencyProductList();
  await getActivityProductList();
  await getRecommendProductList();
};

// const onSwiper = (swiper) => {
//   console.log(swiper);
// };
// const onSlideChange = () => {
//   console.log("slide change");
// };

onBeforeMount(async () => {
  refreshing.value = true;
  await getBannerList().then((res) => {
    bannerList.value = res.data || [];
  });
  await getNoticeList().then((res) => {
    let allList = res.data || [];
    noticeList.value = allList.filter((item) => item.type == 0);
    dialogNoticeList.value = allList.filter((item) => item.type == 1);
    if (dialogNoticeList.value.length > 0) {
      showNoticeDialog.value = true;
    }
  });

  // 获取抽奖入口信息
  await getHomeDrawInfo({}).then((res) => {
    let data = res.data || {};
    homeDrawInfo = Object.assign(homeDrawInfo, data);
  });
  await getFosterProductList();
  // await getHelpProductList();
  // await getAgencyProductList();
  await getActivityProductList();
  await getRecommendProductList();
});

// 显示下一个弹窗公告
const changeNextNotice = () => {
  if (dialogNoticeIndex.value < dialogNoticeList.value.length - 1) {
    dialogNoticeIndex.value++;
    setTimeout(() => {
      showNoticeDialog.value = true;
    }, 800);
  } else {
    //设置1天过期时间
    let cdays = 1;
    // curDate.setTime(curDate.getTime() + 24 * 60 * 60 * 1000 * cdays);
    let expires = new Date(new Date() * 1 + 1000 * 24 * 60 * 60 * cdays);
    Cookies.set("showDialogFinish", "true", {
      expires: expires,
    }); // n天后失效
  }
};

// 切换下一个滚动通知
const changeNoticeContent = () => {
  if (noticeIndex.value >= noticeList.value.length - 1) {
    noticeIndex.value = 0;
  } else {
    noticeIndex.value++;
  }
};

onBeforeMount(() => {
  // 判断是否还需要弹窗
  showDialogFinish.value = Cookies.get("showDialogFinish") == "true";
  console.log("showDialogFinish:", showDialogFinish.value);
});
</script>

<style scoped lang="scss">
.home-container {
  background-color: var(--van-gray-1);
  min-height: 100%;
  position: relative;
  padding-top: 88px;

  .search-box {
    position: fixed;
    width: 100%;
    top: 88px;
    left: 0px;
    z-index: 10;
  }

  .notice-bar-box {
    height: 75px;
    background-color: #fff;
  }

  .product-type-item {
    width: calc(100% - 32px);
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px auto;
    padding-bottom: 13px;

    // padding: 0px 10px;
    .title-box {
      width: 100%;
      height: 85px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 23px 15px;
      color: #121924;
      font-size: 38px;
      font-weight: normal;

      .title {
        color: black;
      }

      .more-btn {
        font-family: HelveticaNeue;
        font-size: 26px;
        font-weight: normal;
        color: #1889fa;
      }
    }
  }

  .product-not-shopping-list {
    width: 750px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4px 0px;

    .product-not-shopping-item {
      width: 239px;
      height: auto;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      padding: 0px 6px;

      .pic {
        width: 100%;
        height: 228px;
        border-radius: 8px;
      }

      .seckill-pic {
        width: 100%;
        height: 228px;
        border-radius: 8px 8px 0 0;
      }

      .seckill-pic-under {
        width: 100%;
        height: 44px;
        background: linear-gradient(90deg, #fd474a 0%, #d85bda 100%);
        border-radius: 0 0 8px 8px;
        // margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 6px;

        .seckill-pic-under-describe {
          width: 69px;
          height: 27px;
          border-radius: 12px;
          line-height: 27px;
          text-align: center;
          background: white;
          margin-right: 10px;

          & span {
            display: inline-block;
            color: #fc474b;
            font-size: 18px;
            white-space: nowrap;
            font-family: PingFangSC-Medium;
          }
        }
      }

      .seckill-name {
        font-family: HelveticaNeue;
        font-size: 26px;
        font-weight: normal;
        color: #848484;
        margin-top: 5px;
      }

      .name {
        font-size: 20px;
        margin-top: 10px;
      }

      .desc {
        font-size: 10px;
        color: rgb(180, 180, 180);
      }

      .price {
        font-size: xx-small;
        color: #ff5000;

        .price-main {
          font-size: large;
        }
      }
    }
  }

  .recommend-title-box {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 50px;
    font-family: HelveticaNeue;
    font-size: 32px;
    font-weight: normal;

    color: #121924;
    margin: 38px 0px;
  }

  .product-shopping-list {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .skeleton-content-box {
    width: 100%;

    & > :deep(.van-skeleton-image) {
      width: 100%;
      height: 163px;
    }

    .skeleton-text-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
  }
}

.van-image {
  background-color: #fff;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.van-notice-bar {
  margin: 4px 0;
}

.lottry-entry-box {
  width: 100%;
  margin: 20px auto 10px auto;

  & img {
    width: 100%;
    height: 168px;
    border-radius: 10px;
  }
}

.advSwipe {
  width: 100%;
  .swipe-img {
    width: 100%;
    height: 100%;
    // transform: translateX(33px);
    text-align: center;
  }
}

.notive-content-box {
  box-sizing: border-box;
  padding: 10px 15px;
  overflow-y: auto;
  min-height: 360px;
  max-height: 680px;
  // .inscribe-time {
  //   margin: 3px 0px;
  //   text-align: right;
  //   font-size: 26px;
  // }
}
</style>
