<template>
  <div>
    <van-nav-bar
        class="bar"
        title="乡愁生态农场APP隐私政策"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />
  <div style="margin: 30px; padding-bottom: 30px">
    <h3 style="text-align: center">乡愁生态农场APP隐私政策</h3>
    欢迎使用我们开发的应用程序（以下简称“APP”）。我们十分注重您的隐私保护，本隐私政策将告知您我们如何收集、使用、保存和保护您的个人信息。

    <h3>一、收集的信息类型</h3>
    <p><span style="font-weight: bold">注册信息：</span>为了让您正常使用APP及相关服务，我们会收集并存储您的注册信息，包括但不限于用户名、密码、手机号码等；</p>
    <p><span style="font-weight: bold">订单信息：</span>当您在APP上购买商品时，我们需要收集您的订单信息，包括但不限于商品详情、订单金额、配送地址等；</p>
    <p><span style="font-weight: bold">交易记录：</span>当您使用APP完成交易时，我们会记录您的交易信息，包括但不限于交易时间、交易金额、交易状态等；</p>
    <p><span style="font-weight: bold">设备信息：</span>我们会收集您使用APP的设备信息，包括但不限于设备型号、操作系统版本、唯一设备标识符等；</p>
    <p><span style="font-weight: bold">日志信息：</span>为改善我们的服务质量和优化用户体验，我们会记录您在APP中的操作日志、错误日志等；</p>
<!--    <p><span style="font-weight: bold">地理位置信息：</span>当您使用APP的定位功能时，我们会收集您的地理位置信息；</p>-->
    <p><span style="font-weight: bold">其他信息：</span>如果您向我们反馈意见或建议，我们会收集您的联系方式和反馈内容等。</p>

    <h3>二、信息的使用</h3>
    <p>我们仅会出于下列目的使用您的个人信息：</p>

    <p><span>1</span>、向您提供我们的产品或服务，包括但不限于商品购买、配送等；</p>
    <p><span>2</span>、验证您的身份，以便为您提供更好的客户支持服务；</p>
    <p><span>3</span>、分析和改善我们的产品或服务；</p>
    <p><span>4</span>、为您提供个性化推荐和营销活动；</p>
    <p><span>5</span>、遵守法律法规等规定。</p>

    <h3>三、信息的共享</h3>
    <p>我们承诺，除非获得您的明确同意，否则不会与第三方共享您的个人信息，但在以下情况下，我们有权共享您的个人信息：</p>

    <p><span>1</span>、法律法规等规定允许或要求；</p>
    <p><span>2</span>、为便于我们向您提供产品或服务，需要与第三方分享您的个人信息；</p>
    <p><span>3</span>、我们与第三方合作进行市场调研或统计分析，需要共享您的个人信息。</p>

    <h3>四、信息的保护</h3>
    <p>我们将采取各种安全措施来保护您的个人信息，包括但不限于：</p>

    <p><span>1</span>、严格限制访问您个人信息的人员范围；</p>
    <p><span>2</span>、通过加密等方式确保您的个人信息在传输和存储过程中的安全性；</p>
    <p><span>3</span>、定期审查并更新安全措施。</p>

    <h3>五、未成年人信息保护</h3>
    我们重视未成年人信息的保护。若您为未满18周岁的未成年人，请在法定监护人的指导下使用我们的产品或服务，并请您的监护人仔细阅读本隐私政策。

    <h3>六、隐私政策的修订</h3>
    我们有权对本隐私政策进行更新或修改。如有任何实质性变更，我们会在APP上发布通知，并征得您明示同意后才继续收集、使用您的个人信息。

    <h3>七、联系我们</h3>
    如您对本隐私政策内容有任何疑问或建议，欢迎随时与我们联系，我们将尽快回复您的问题。
  </div>
  </div>
</template>

<script>
export default {
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
};
</script>

<style scoped>
.bar{
    --van-nav-bar-icon-color: black;
    --van-nav-bar-arrow-size:52px;
    --van-nav-bar-text-color: black;
    --van-nav-bar-title-font-size: 36px;
    --van-nav-bar-title-text-color:black;
}
</style>
