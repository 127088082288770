<template>
  <div class="register">
    <BackNavBar :left="false" title="注册" />

    <van-form @submit="onSubmit">
      <van-cell-group>
        <van-field
          required
          v-model="formInfo.name"
          name="name"
          label="昵称"
          placeholder="请输入昵称"
          :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <van-field
          required
          v-model="formInfo.account"
          name="account"
          label="手机号"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请填写手机号' },
            {
              validator: validatorPhone,
              message: '请输入正确手机号码',
            },
          ]"
        />
        <van-field
          required
          v-model="formInfo.password"
          type="password"
          name="password"
          label="密码"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />

        <van-field
          required
          v-model="formInfo.password2"
          type="password"
          name="password2"
          label="确认密码"
          placeholder="请输入确认密码"
          :rules="[
            { required: true, message: '请填写确认密码' },
            {
              validator: (val) => formInfo.password === val,
              message: '两次输入的密码不一致',
            },
          ]"
        />
        <van-field
          required
          v-model="formInfo.verifyCode"
          center
          label-width=""
          name="verifyCode"
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button
              class="verify-code"
              @click="showVerifyDialogHandle"
              :disabled="countDown.current.value.seconds > 0"
              size="small"
              type="primary"
              >{{
                countDown.current.value.seconds > 0
                  ? `${countDown.current.value.seconds}秒`
                  : "发送验证码"
              }}
            </van-button>
          </template>
        </van-field>
        <van-field
          v-model="formInfo.inviteCode"
          name="inviteCode"
          label="邀请码"
          placeholder="邀请码"
        />
        <!-- <van-field
          label=""
          placeholder="如若无邀请者，请填写XCST001"
          disabled
        >
          <template #button>
            <van-button
              class="verify-code"
              @click="formInfo.inviteCode = 'XCST001'"
              size="mini"
              type="primary"
            >
              一键填写
            </van-button>
          </template>
        </van-field> -->
      </van-cell-group>

      <div class="van-hairline--bottom"></div>

      <div style="margin: 16px">
        <van-button
          :loading="submitLoading"
          loading-text="提交中..."
          round
          block
          type="primary"
          native-type="submit"
        >
          提交
        </van-button>

      </div>

      <div style="margin: 16px">
        <van-button @click="toLoginPage" plain type="primary" round block>
          已有账号，去登录
        </van-button>
      </div>
    </van-form>

    <VerifyDialog
      v-model:show="showVerifyDialog"
      :phone="formInfo.account"
      :type="2"
      @confirm="dialogConfirm"
    />
  </div>
</template>
<script setup>
import { reactive, ref, toRaw } from "vue";
import { userRegister, getUserInfo } from "@/api/user";
import { showFailToast } from "vant";
import { setToken, setUserInfo, removeUserInfo } from "@/api/token";
import { useRouter, useRoute } from "vue-router";
import { util } from "@/common/validate";
import BackNavBar from "@/components/BackNavBar.vue";
import VerifyDialog from "@/components/VerifyDialog.vue";
import { useCountDown } from "@vant/use";
import { cleanKeepAlive } from "@/hooks/keepAliveRouter";
removeUserInfo(); // 清除用户信息
cleanKeepAlive(); // 清除缓存
const router = useRouter();
const route = useRoute();
let backOri = route.query.backOri || false
const formInfo = reactive({
  name: "",
  account: "",
  password: "",
  password2: "",
  inviteCode: route.query.code || "",
  uuid: "",
  verifyCode: "",
});

// 60秒倒计时
const countDown = useCountDown({
  time: 60 * 1000,
  onFinish() {
    countDown.reset(60 * 1000);
  },
});

const showVerifyDialog = ref(false);
const submitLoading = ref(false);

// 校验手机号
const validatorPhone = (val) => util.isPhone(val);

const showVerifyDialogHandle = function () {
  if (validatorPhone(formInfo.account)) {
    showVerifyDialog.value = true;
  } else {
    showFailToast("请填写正确的手机号");
  }
};

const dialogConfirm = function (res) {
  const { data } = res;
  if (res.type === "production") {
    formInfo.uuid = data.uuid;
  } else {
    formInfo.uuid = data.uuid;
    formInfo.verifyCode = data.img;
  }
  countDown.start();
};

function onSubmit() {
  submitLoading.value = true;
  const info = JSON.parse(JSON.stringify(toRaw(formInfo)));

  Reflect.deleteProperty(info, "password2");
  userRegister(info)
    .then((res) => {
      const data = res.data || {};
      setToken(data.token);
      return getUserInfo();
    })
    .then((res) => {
      const data = res.data || {};
      setUserInfo(data);

      if(backOri){
        router.go(-1);
      } else {
        router.push("/home");
      }
    })
    .finally(() => {
      submitLoading.value = false;
    });
}

function toLoginPage() {
  router.replace("/login");
}

</script>
<style scoped>
.register .van-button.verify-code {
  width: 7.5em;
}
</style>
