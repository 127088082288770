import { http } from "./request";

/**
 * 获取短信验证码，测试使用
 * @param {Object} params
 * @returns
 */
export const getTestSMS = (params) => {
  return http.get("/system/captcha/getSMS/" + params.phone);
};

/**
 * 获取短信验证码，正式使用
 * @param {Object} params
 * @returns
 */
export const sendSMS = (params) => {
  return http.get("/system/captcha/sendSMS", { params });
};

/**
 * 获取图片验证码
 * @returns
 */
export const getPicImage = () => {
  return http.get("/system/captcha/get-image");
};
/**
 * 注册
 * @param {Object} params
 * @returns
 */
export const userRegister = (params) => {
  return http.post("/mall/user/memberRegister", params);
};

/**
 * 登录
 * @param {Object} params
 * @returns
 */
export const userLogin = (params) => {
  return http.post("/mall/user/login", params);
};

/**
 * 退出登录
 * @param {Object} params
 * @returns
 */
export const userLogout = (params) => {
  return http.post("/logout", params);
};

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = () => {
  return http.get("/mall/user/getProfile");
};

/**
 * 获取当前用户贡献值
 * @returns
 */
export const getCurUserIntegral = function () {
  return http.get("/pomelo/sharing/getCurUserIntegral");
};

/**
 * 获取协议
 * id => 1:风险告知书，2：柚子树认购合同，3：柚子树认购协议
 * @param {Object} params
 * @returns
 */
export const getAgreement = function (params) {
  return http.get("/pomelo/agreement/get", { params });
};

/**
 * 实名认证
 * @param {Object} params
 * @returns
 */
export const updateUserCardInfo = function (params) {
  return http.get("/mall/user/up-card-info", { params });
};

/**
 * 修改密码
 * @param {Object} params
 * @returns
 */
export const updateUserPassword = function (params) {
  return http.put("/system/user/profile/sms-code-up-password", params);
};

/**
 * 修改用户信息
 * @param {Object} params
 * @returns
 */
export const updateUserInfo = function (params) {
  return http.get("/mall/user/up-user-info", { params });
};

/**
 * 数据统计
 * @return
 */
export const getHomeNum = function (){
  return http.get("/pomelo/admin-index/homeNum")
}

