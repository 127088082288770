import { http } from "./request";

/**
 * 获取首页轮播图
 * @returns
 */
export const getBannerList = function () {
  return http.get("/pomelo/index/banner-list");
};

/**
 * 获取首页通知
 * @returns
 */
export const getNoticeList = function () {
  return http.get("/pomelo/index/notice-list");
};

/**
 * 获取推广明细
 * @param {Object} params
 * @returns
 */
export const getPopularizeRecord = function (params) {
  return http.get("/pomelo/sharing/userSonPage", { params });
};

/**
 * 上传图片
 * @param {Object} params
 * @returns
 */
export const uploadFile = function (params) {
  return http.post("/system/comPub/Upload/file", params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * 获取抽奖入口信息
 * @param {Object} params
 * @returns
 */
export const getHomeDrawInfo = function (params) {
  return http.get("/pomelo/index/getHomeDrawInfo", { params });
};
