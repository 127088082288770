import { reactive, readonly } from "vue";
const keepAliveRouter = reactive([]);
// KeepAlive缓存
export const useKeepAlive = function (key) {
  if (!key) return;
  const cancel = () => {
    removeKeepAlive(key);
    key = null;
  };
  if (keepAliveRouter.includes(key)) return cancel;
  keepAliveRouter.push(key);
  return cancel;
};

// 删除缓存
export const removeKeepAlive = function (key) {
  if (!key) return console.warn(`key=${key}，找不到缓存页面`);
  const i = keepAliveRouter.indexOf(key);
  if (i > -1) {
    keepAliveRouter.splice(i, 1);
  } else {
    console.warn(`key=${key}，找不到缓存页面`);
  }
};

export const cleanKeepAlive = function () {
  keepAliveRouter.length = 0;
};

export const keepAliveInclude = readonly(keepAliveRouter);
