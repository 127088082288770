<template>
  <div class="my-commission-sale-list-page">
    <van-nav-bar fixed placeholder title="我的寄售" />
    <van-tabs v-model:active="saleStatusActive" sticky offset-top="12.195vw">
      <van-tab title="出售中" name="unSoldData">
        <van-pull-refresh class="commission-sale-list-box" success-text="刷新成功"
          v-model="commissionSaleAllData.unSoldData.refreshing" @refresh="onRefresh">
          <van-list class="commission-sale-list" v-model:loading="commissionSaleAllData.unSoldData.loading"
            :finished="commissionSaleAllData.unSoldData.finished" finished-text="没有更多了" @load="onLoad">
            <van-swipe-cell class="commission-sale-list-card" v-for="item in commissionSaleAllData.unSoldData.list"
              :key="item.id">
              <div>
                <div class="commission-sale-list-card-top">
                  <span>
                    订单号：{{ item.orderNo }}
                  </span>
                  <span>
                    出售中
                  </span>
                </div>
                <div class="commission-sale-list-card-middle">
                  <van-card class="sale-product-item" :thumb="item.productDO.pic" @click="seeProductDetails(item)">
                    <template #desc>
                      <div class="sale-product-item-top">
                        <div>{{ item.productDO.name }}</div>
                        <div>￥{{ item.skuPrice.toFixed(2) }}</div>
                      </div>
                    </template>
                    <template #price>
                      <div class="sku-spdata">
                        <span>规格：</span>
                        <span v-for="item in item.skuDO.spData" :key="item.key">{{ item.value }}；</span>
                      </div>
                    </template>
                    <template #num>
                      <div class="sku-quantity">x{{ item.skuQuantity }}</div>
                    </template>
                    <template #footer>
                      <div class="commission-sale-list-card-bottom">
                        <span>共</span>
                        <span class="commission-sale-list-card-bottom-num">{{ ' ' + 1 + ' ' }}</span>
                        <span>件商品</span>
                        <span class="commission-sale-list-card-bottom-price">￥{{ item.skuPrice.toFixed(2) }}</span>
                        <van-button round color="#FF4E1E" size="normal">分享出售</van-button>
                      </div>
                    </template>
                  </van-card>
                </div>
              </div>
            </van-swipe-cell>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已售出" name="soldData">
        <van-pull-refresh class="commission-sale-list-box" success-text="刷新成功"
          v-model="commissionSaleAllData.soldData.refreshing" @refresh="onRefresh">
          <van-list class="commission-sale-list" v-model:loading="commissionSaleAllData.soldData.loading"
            :finished="commissionSaleAllData.soldData.finished" finished-text="没有更多了" @load="onLoad">
            <van-swipe-cell class="commission-sale-list-card" v-for="item in commissionSaleAllData.soldData.list"
              :key="item.id">
              <div>
                <div class="commission-sale-list-card-top">
                  <span>
                    订单号:{{ item.orderNo }}
                  </span>
                  <span>
                    已售出
                  </span>
                </div>
                <div class="commission-sale-list-card-middle">
                  <van-card class="sale-product-item" :thumb="item.productDO.pic" @click="seeProductDetails(item)">
                    <template #desc>
                      <div class="sale-product-item-top">
                        <div>{{ item.productDO.name }}</div>
                        <div>￥{{ item.skuPrice.toFixed(2) }}</div>
                      </div>
                    </template>
                    <template #price>
                      <div class="sku-spdata">
                        <span>规格：</span>
                        <span v-for="item in item.skuDO.spData" :key="item.key">{{ item.value }}；</span>
                      </div>
                    </template>
                    <template #num>
                      <div class="sku-quantity">x{{ item.skuQuantity }}</div>
                    </template>
                    <template #footer>
                      <div class="commission-sale-list-card-bottom">
                        <span>共</span>
                        <span class="commission-sale-list-card-bottom-num">{{ ' ' + 1 + ' ' }}</span>
                        <span>件商品</span>
                        <span class="commission-sale-list-card-bottom-price">￥{{ item.skuPrice.toFixed(2) }}</span>
                      </div>
                    </template>
                  </van-card>
                </div>
              </div>
            </van-swipe-cell>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { getConsignmentSaleList } from "@/api/mall/consignmentSales.js";
import { useKeepAlive } from "@/hooks/keepAliveRouter";
// useKeepAlive("MyCommissionSaleList");
const saleStatusActive = ref("unSoldData");

const router = useRouter();
let commissionSaleAllData = reactive({
  soldData: {
    pageNo: 1,
    pageSize: 10,
    list: [],
    loading: false,
    finished: false,
    refreshing: false,
  },
  unSoldData: {
    pageNo: 1,
    pageSize: 10,
    list: [],
    loading: false,
    finished: false,
    refreshing: false,
  },
});

const onLoad = function () {
  let curCommissionSaleData = commissionSaleAllData[saleStatusActive.value];
  // 将 loading 设置为 true，表示处于加载状态
  curCommissionSaleData.loading = true;
  getConsignmentSaleList({
    pageNo: curCommissionSaleData.pageNo,
    pageSize: curCommissionSaleData.pageSize,
    isBeSold: saleStatusActive.value == "unSoldData" ? 0 : 1,
  })
    .then((res) => {
      if (curCommissionSaleData.refreshing == true) {
        curCommissionSaleData.list = [];
      }
      if (res.data) {
        if (curCommissionSaleData.list.length === res.data.total) {
          curCommissionSaleData.finished = true;
        } else {
          res.data.list.map((item) => {
            if (item.skuDO.spData) {
              item.skuDO.spData = JSON.parse(item.skuDO.spData)
            }
            return item
          })
          curCommissionSaleData.list = [...curCommissionSaleData.list, ...res.data.list];
          curCommissionSaleData.pageNo++;
        }
      }
    })
    .catch(() => {
      curCommissionSaleData.finished = true;
    })
    .finally(() => {
      curCommissionSaleData.loading = false;
      curCommissionSaleData.refreshing = false;
    });
};

// const seeProductDetails = function (item) {
//   let id = item.productId;
//   router.push({ name: "productDetails", params: { id } });
// };

const seeProductDetails = (item) => {
  // console.log(item);
  router.push({
    path: "/shopping/productDetail",
    query: { id: item.productId, consignmentId: item.id },
  });
};

onBeforeMount(() =>{
  let curCommissionSaleData = commissionSaleAllData[saleStatusActive.value];
  // 清空列表数据
  curCommissionSaleData.refreshing = true;
  curCommissionSaleData.pageNo = 1;
  curCommissionSaleData.finished = false;
  curCommissionSaleData.loading = false;
  console.log('.abaaba==================');
  onLoad()
});
</script>

<style lang="scss">
.my-commission-sale-list-page {
  min-height: calc(100vh - 70px);
  background-color: var(--van-gray-1);

  .commission-sale-list-box {
    margin-top: 8px;

    .commission-sale-list {

      //min-height: calc(100vh - 140px);
      .commission-sale-list-card {
        width: 95%;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 10px;
        background: white;

        .commission-sale-list-card-top {
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0px 20px;
          margin: auto;
          text-align: center;
          border-bottom: 1px #D4D4D4 dotted;
          font-family: PingFangSC-Regular;
          font-size: 24px;
          font-weight: normal;

          & span:nth-child(1) {
            color: #131417;
          }

          & span:nth-child(2) {
            color: #06B577;
          }
        }

        .commission-sale-list-card-middle {
          .sale-product-item {
            //width: calc(100% - 16px);
            margin-left: auto;
            margin-right: auto;
            background-color: #fff !important;

            .sale-product-item-top {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;

              & div:nth-child(1) {
                font-family: PingFangSC-Regular;
                font-size: 32px;
                font-weight: normal;
                color: #6F6F71;
              }

              & div:nth-child(2) {
                font-family: PingFangSC-Regular;
                font-size: 28px;
                font-weight: normal;
                color: #6F6F71;
              }
            }

            .sale-product-item-bottom {
              display: flex;
              justify-content: space-between;
              color: #A3A3A3;
              margin-top: 35px;
            }

            .sku-spdata {
              font-family: PingFangSC-Regular;
              font-size: 22px;
              font-weight: normal;
              color: #A3A3A3;
            }

            .sku-quantity {
              font-family: PingFangSC-Regular;
              font-size: 22px;
              font-weight: normal;
              color: #A3A3A3;
            }
          }
        }

        .commission-sale-list-card-bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          color: #A3A3A3;
          font-size: small;
          font-family: PingFangSC-Regular;
          font-size: 22px;
          font-weight: normal;
          color: #A3A3A3;
          padding: 20px 0px;

          .commission-sale-list-card-bottom-num {
            color: #121924;
          }

          .commission-sale-list-card-bottom-price {
            font-family: PingFangSC-Semibold;
            font-size: 42px;
            font-weight: normal;
            color: #121924;
            margin-left: 20px;
          }
        }
      }
    }
  }

}
</style>
