const TokenKey = "Admin-Token";
import { getUserInfo as getUserInfoRequest } from "@/api/user";
import { cleanKeepAlive } from "@/hooks/keepAliveRouter";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  removeUserInfo();
  cleanKeepAlive();
  return localStorage.removeItem(TokenKey);
}

const UserKey = "User-Info";

export async function getUserInfo() {
  const userInfo = localStorage.getItem(UserKey) || "{}";
  if (userInfo === "{}") {
    const res = await getUserInfoRequest();
    setUserInfo(res.data);
    return res.data;
  } else {
    return JSON.parse(userInfo);
  }
}

export function getCacheUserInfo() {
  const userInfo = localStorage.getItem(UserKey) || "{}";
  return JSON.parse(userInfo);
}

export function setUserInfo(userInfo = {}) {
  return localStorage.setItem(UserKey, JSON.stringify(userInfo));
}

export function removeUserInfo() {
  return localStorage.removeItem(UserKey);
}
