import axios from "axios";
import { showFailToast } from "vant";
import { getToken, removeToken } from "./token";
const errorCode = {
  401: "认证失败，无法访问系统资源",
  403: "当前操作没有权限",
  404: "访问资源不存在",
  500: "网络异常，请稍后重试",
  default: "网络异常，请反馈给管理员",
};

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const baseURL =
  process.env.VUE_APP_FLAG === "development"
    ? "/dev-api"
    : process.env.VUE_APP_REQUEST_URL;
console.log(baseURL, process.env.VUE_APP_REQUEST_URL);
// 创建axios实例
export const http = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseURL,
  // 超时
  timeout: 10000,
});
// request拦截器
http.interceptors.request.use(
  (config) => {
    // 让每个请求携带自定义token 请根据实际情况自行修改
    const token = getToken() || "";
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  (res) => {
    const data = res.data || {};
    // 未设置状态码则默认成功状态
    const code = data.code || 200;

    // 获取错误信息
    const msg = errorCode[code] || data.msg || errorCode["default"];
    const isLoginPage = location.pathname === "/login";
    if (code === 401) {
      // 登录过期
      showFailToast("登录过期");
      removeToken();
      if (!isLoginPage) {
        setTimeout(() => {
          location.href = "/login";
        }, 1500);
      }
      // else if (code === 500) {
      //   showFailToast(msg);
      //   return Promise.reject(msg);
      // } else if (code === 901) {
      //   showFailToast(msg);
      //   return Promise.reject(msg);
      // }
    } else if (code !== 200) {
      showFailToast(msg);
      return Promise.reject(msg);
    } else {
      return data;
    }
  },
  (error) => {
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    console.error(message, error);
    return Promise.reject(error);
  }
);
