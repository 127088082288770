<template>
  <van-nav-bar
    :title="props.title"
    :left-text="left ? leftText : ''"
    :left-arrow="left"
    fixed
    placeholder
    @click-left="onClickLeft"
  />
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  title: String,
  leftText: {
    type: String,
    default: "返回",
  },
  left: {
    type: Boolean,
    default: true,
  },
});
const onClickLeft = function () {
  router.back();
};
</script>
