import { http } from "./request";


/**
 * 否开启提现入口
 * @returns
 */
export const openWithdrawals = function () {
  return http.get("/pomelo/withdrawals-apply/openWithdrawals");
};

/**
 * 计算收益
 * @returns
 */
export const calcIncome = function (params) {
  return http.get("/pomelo/index/calcIncome", { params });
};

/**
 * 今日收益汇总
 * @returns
 */
export const getTodayIncomeTotal = function () {
  return http.get("/pomelo/user-income/getTodayIncome");
};

/**
 * 今日收益明细
 * @returns
 */
export const getTodayIncomeInfo = function () {
  return http.get("/pomelo/user-income/getTodayIncomeInfo");
};

/**
 * 用户-获得用户收益分页
 * @param {Object} params
 * @returns
 */
export const getUserIncomeList = function (params) {
  return http.get("/pomelo/user-income/userPage", { params });
};

/**
 * 用户收益总汇
 * @returns
 */
export const getUserIncomeTotal = function () {
  return http.get("/pomelo/user-income-data/getUserIncome");
};

/**
 * 用户摘取成长收益
 * @returns
 */
export const userPickIncome = function (params) {
  return http.get("/pomelo/user-income-data/userPickIncome", { params });
};

/**
 * 推广收益
 * @param {Object} params
 * @returns
 */
export const getUserSharingIncomeList = function (params) {
  return http.get("/pomelo/sharing/userSonIncomePage", { params });
};

/**
 * 推广人数
 * @param {Object} params
 * @returns
 */
export const getShareUserCount = function (params) {
  return http.get("/pomelo/sharing/getShareUserCount", { params });
};

/**
 * 收入明细分页
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBrokerageList = function (params) {
  return http.post("/sqsm/trade-brokerage/list", params);
};

/**
 * 收入明细统计
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBrokerageCount = function () {
  return http.post("/sqsm/trade-brokerage/order-count");
};

/**
 * 提现列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBrokerageWithdrawList = function (params) {
  return http.post("/sqsm/trade-brokerage/withdraw-list", params);
};

/**
 * 获得佣金提现分页
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const userWithdrawPage = function (params) {
  return http.get("/sqsm/trade-withdrawals-record/user-page", { params });
};

/**
 * 提现申请
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const userWithdrawCreate = function (params) {
  return http.post("/sqsm/trade-withdrawals-record/create", params);
};

/**
 * 提现取消
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const userWithdrawCancel = function (params) {
  return http.get("/sqsm/trade-withdrawals-record/cancel", { params });
};