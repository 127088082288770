import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "@/api/token";
import Home from "@/views/Home.vue";
import Me from "@/views/Me.vue";
import MyFosterProduct from "@/views/MyFosterProduct.vue";
import MyCommissionSaleList from "@/views/MyCommissionSaleList.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import Privacy from "@/views/Privacy.vue";
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "home",
    path: "/home",
    component: Home,
  },
  {
    name: "incomePick",
    path: "/incomePick",
    component: () =>
      import(
        /* webpackChunkName: "draft" */ "@/views/incomePick.vue"
        ),
  },
  {
    path: "/myFosterProduct",
    name: "myFosterProduct",
    component: MyFosterProduct,
  },
  {
    path: "/myCommissionSaleList",
    name: "myCommissionSaleList",
    component: MyCommissionSaleList,
  },
  {
    path: "/me",
    name: "me",
    component: Me,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/me/userWallet",
    name: "userWallet",
    component: () =>
      import(
        /* webpackChunkName: "userWallet" */ "@/views/me/userWallet/UserWallet.vue"
      ),
  },
  {
    path: "/me/userWallet/updateWalletPassword",
    name: "updateWalletPassword",
    component: () =>
      import(
        /* webpackChunkName: "updateWalletPassword" */ "@/views/me/userWallet/UpdateWalletPassword.vue"
      ),
  },
  {
    path: "/me/userWallet/forgetWalletPassword",
    name: "forgetWalletPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgetWalletPassword" */ "@/views/me/userWallet/ForgetWalletPassword.vue"
      ),
  },
  {
    path: "/me/earnings",
    name: "earnings",
    component: () =>
      import(/* webpackChunkName: "earnings" */ "@/views/me/Earnings.vue"),
  },
  {
    path: "/me/brokerage",
    name: "brokerage",
    component: () =>
      import(/* webpackChunkName: "earnings" */ "@/views/me/Brokerage.vue"),
  },
  {
    path: "/me/dataStatistics",
    name: "dataStatistics",
    component: () =>
      import(/* webpackChunkName: "earnings" */ "@/views/me/DataStatistics.vue"),
  },

  {
    path: "/incomeData",
    name: "incomeData",
    component: () =>
      import(/* webpackChunkName: "earnings" */ "@/views/IncomeData.vue"),
  },
  {
    path: "/shareProductPage",
    name: "shareProductPage",
    component: () =>
      import(/* webpackChunkName: "earnings" */ "@/views/shareProductPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/foster/productDetail",
    name: "fosterProductDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // 懒加载
    component: () =>
      import(
        /* webpackChunkName: "fosterProductDetail" */ "@/views/foster/FosterProductDetail.vue"
      ),
  },
  {
    path: "/foster/orderList",
    name: "fosterOrderList",
    component: () =>
      import(
        /* webpackChunkName: "orderList" */ "@/views/foster/OrderList.vue"
      ),
  },
  {
    path: "/shopping/orderList",
    name: "shoppingOrderList",
    component: () =>
      import(
        /* webpackChunkName: "orderList" */ "@/views/shopping/OrderList.vue"
      ),
  },
  {
    path: "/sharingIncome",
    name: "sharingIncome",
    component: () =>
      import(
        /* webpackChunkName: "sharingIncome" */ "@/views/SharingIncomeList.vue"
      ),
  },
  {
    path: "/dayIncome",
    name: "dayIncome",
    component: () => import("@/views/DayIncomeList.vue"),
  },
  {
    path: "/dayLevelIncome",
    name: "dayLevelIncome",
    component: () => import("@/views/DayLevelIncomeList.vue"),
  },
  {
    path: "/foster/orderDetail",
    name: "fosterOrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "orderDetails" */ "@/views/foster/OrderDetails.vue"
      ),
  },
  {
    path: "/authentication",
    name: "authentication",
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/views/AuthenticationView.vue"
      ),
  },
  {
    path: "/paymentAccount",
    name: "paymentAccount",
    component: () =>
      import(
        /* webpackChunkName: "paymentAccount" */ "@/views/PaymentAccountView.vue"
      ),
  },
  {
    path: "/paymentAccountForm",
    name: "paymentAccountForm",
    component: () =>
      import(
        /* webpackChunkName: "paymentAccountForm" */ "@/views/PaymentAccountFormView.vue"
      ),
  },
  {
    path: "/popularizeRecord",
    name: "popularizeRecord",
    component: () =>
      import(
        /* webpackChunkName: "popularizeRecord" */ "@/views/PopularizeRecordView.vue"
      ),
  },
  {
    path: "/updatePassword",
    name: "updatePassword",
    component: () =>
      import(
        /* webpackChunkName: "updatePassword" */ "@/views/UpdatePasswordView.vue"
      ),
  },
  {
    path: "/incomeList",
    name: "incomeList",
    component: () =>
      import(/* webpackChunkName: "incomeList" */ "@/views/IncomeListView.vue"),
  },
  {
    path: "/orderManageDetails/:id",
    name: "orderManageDetails",
    component: () =>
      import(
        /* webpackChunkName: "orderManageDetails" */ "@/views/OrderManageDetails.vue"
      ),
  },
  {
    path: "/updateUserInfo",
    name: "updateUserInfo",
    component: () =>
      import(
        /* webpackChunkName: "updateUserInfo" */ "@/views/UpdateUserInfoView.vue"
      ),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "@/views/WithdrawView.vue"),
  },
  {
    path: "/withdrawShare",
    name: "withdrawShare",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "@/views/WithdrawShareView.vue"),
  },
  {
    path: "/userWithdrawList",
    name: "userWithdrawList",
    component: () =>
      import(
        /* webpackChunkName: "userWithdrawList" */ "@/views/UserWithdrawList.vue"
      ),
  },
  {
    path: "/sharePoster",
    name: "sharePoster",
    component: () =>
      import(
        /* webpackChunkName: "sharePoster" */ "@/views/SharePosterView.vue"
      ),
  },
  {
    path: "/me/userWallet/consumeRecord",
    name: "consumeRecord",
    component: () =>
      import(
        /* webpackChunkName: "consumeRecord" */ "@/views/me/userWallet/ConsumeRecord.vue"
      ),
  },
  {
    path: "/foster/fosterProducts",
    name: "fosterProducts",
    component: () =>
      import(
        /* webpackChunkName: "fosterProducts" */ "@/views/foster/fosterProducts.vue"
      ),
  },
  {
    path: "/me/userWallet/walletSetting",
    name: "walletSetting",
    component: () =>
      import(
        /* webpackChunkName: "walletSetting" */ "@/views/me/userWallet/walletSetting.vue"
      ),
  },
  {
    path: "/shopping/searchProducts",
    name: "shoppingSearchProducts",
    component: () =>
      import(
        /* webpackChunkName: "searchProducts" */ "@/views/shopping/SearchProducts.vue"
      ),
  },
  {
    path: "/shopping/productDetail",
    name: "shoppingProductDetail",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/shopping/ProductDetail.vue"
      ),
  },
  {
    path: "/shopping/productRefund",
    name: "shoppingProductRefund",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/shopping/ProductRefund.vue"
      ),
  },
  {
    path: "/shopping/productRefundDetail",
    name: "shoppingProductRefundDetail",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/shopping/ProductRefundDetail.vue"
      ),
  },
  {
    path: "/shopping/productReview",
    name: "shoppingProductReview",
    component: () =>
      import(
        /* webpackChunkName: "productDetail" */ "@/views/shopping/ProductReview.vue"
      ),
  },
  {
    path: "/shopping/addressList",
    name: "addressList",
    component: () =>
      import(
        /* webpackChunkName: "addressList" */ "@/views/shopping/AddressList.vue"
      ),
  },
  {
    path: "/shopping/updateAddress",
    name: "updateAddress",
    component: () =>
      import(
        /* webpackChunkName: "updateAddress" */ "@/views/shopping/UpdateAddress.vue"
      ),
  },
  {
    path: "/shopping/createOrder",
    name: "shoppingCreateOrder",
    component: () =>
      import(
        /* webpackChunkName: "shoppingCreateOrder" */ "@/views/shopping/CreateOrder.vue"
      ),
  },
  {
    path: "/shopping/orderDetail",
    name: "shoppingOrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "shoppingOrderDetail" */ "@/views/shopping/OrderDetail.vue"
      ),
  },
  {
    path: "/shopping/seckillProducts",
    name: "shoppingSeckillProducts",
    component: () =>
      import(
        /* webpackChunkName: "shoppingSeckillProducts" */ "@/views/shopping/SeckillProducts.vue"
      ),
  },
  {
    path: "/foster/consumeRecord",
    name: "fosterConsumeRecord",
    component: () =>
      import(
        /* webpackChunkName: "fosterConsumeRecord" */ "@/views/foster/ConsumeRecord.vue"
      ),
  },
  {
    path: "/me/userInfo",
    name: "meUserInfo",
    component: () =>
      import(/* webpackChunkName: "meUserInfo" */ "@/views/me/UserInfo.vue"),
  },
  {
    path: "/foster/luckyDraw",
    name: "fosterLuckyDraw",
    component: () =>
      import(
        /* webpackChunkName: "meUserInfo" */ "@/views/foster/LuckyDraw.vue"
      ),
  },
  {
    path: "/foster/lottryRecord",
    name: "fosterLottryRecord",
    component: () =>
      import(
        /* webpackChunkName: "LottryRecord" */ "@/views/foster/LottryRecord.vue"
      ),
  },
  {
    path: "/foster/exchangePrize",
    name: "fosterExchangePrize",
    component: () =>
      import(
        /* webpackChunkName: "ExchangePrize" */ "@/views/foster/ExchangePrize.vue"
      ),
  },
  {
    path: "/shopping/alipay",
    name: "ShoppingAliPay",
    component: () =>
      import(
        /* webpackChunkName: "ShoppingAliPay" */ "@/views/shopping/AliPay.vue"
      ),
  },
  {
    path: "/shopping/afterSaleOrderList",
    name: "AfterSaleOrderList",
    component: () =>
      import(
        /* webpackChunkName: "AfterSaleOrderList" */ "@/views/shopping/AfterSaleOrderList.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "AfterSaleOrderList" */ "@/views/about.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 白名单
const whiteList = ["/login", "/register", "/updatePassword", "/home", "/shopping/productDetail", "/privacy", "/"];

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (whiteList.includes(to.path)) {
    if (token && ["/login", "/register"].includes(to.path)) {
      next("/home");
    } else {
      next();
    }
  } else if (token) {
    next();
  } else {
    // 没有token
    next("/login");
  }
});

export const isWhite = whiteList.includes(location.pathname);

export const openAdminRouter = function () {
  router.addRoute({
    path: "/orderManageAdmin",
    name: "orderManageAdmin",
    component: () =>
      import(
        /* webpackChunkName: "orderManageAdmin" */ "@/views/OrderManageAdmin.vue"
      ),
  });

  router.addRoute({
    path: "/withdrawListAdmin",
    name: "withdrawListAdmin",
    component: () =>
      import(
        /* webpackChunkName: "withdrawListAdmin" */ "@/views/WithdrawListAdmin.vue"
      ),
  });
};

export default router;
