<template>
    <div class="me-page">
        <div class="me-content-container">
            <div class="bg-box"></div>
            <div class="me-top-container">
                <div class="user-personal-info">
                    <div class="avatar-box">
                        <img class="avatar" :src="userInfo.photo">
                    </div>
                    <div class="username-box">
                        <span class="name">{{ userInfo.name }} <span class="lv">Lv{{ userInfo.vip }}</span></span>
                        <span class="phone">{{ userInfo.telphone }}</span>
                    </div>
                    <div class="btn-setting" @click="goPageByPath('/me/userInfo')">
                        <img src="../assets/images/me/icon_setting.png">
                    </div>
                </div>
                <div class="user-money-info">
                    <div class="detail-item-box" @click="goPageByPath('/me/earnings')">
                        <span class="big-bum">{{ userIntegral }}</span>
                        <span>贡献值</span>
                    </div>
                    <div class="detail-item-box" @click="goPageByPath('/me/earnings')">
                        <span class="big-bum">{{ available }}</span>
                        <span>积分</span>
                    </div>
                    <div class="detail-item-box" @click="goPageByPath('/sharePoster')" style="border-right: none;">
                        <span class="big-bum">{{ userInfo.inviteCode }}</span>
                        <span>邀请码</span>
                    </div>
                </div>
                <div class="user-order-entry box-shadow">
                    <div class="title-box">
                        <span>我的订单</span>
                        <span @click="goOrderListPage('')">全部订单 ></span>
                    </div>
                    <div class="entry-list-box">
                        <div class="entry-item-box" @click="goOrderListPage(0)">
                            <img class="icon-order" src="../assets/images/me/icon_order_status_pay.png">
                            <span>待付款</span>
                        </div>
                        <div class="entry-item-box" @click="goOrderListPage(1)">
                            <img class="icon-order" src="../assets/images/me/icon_order_status_dfk.png">
                            <span>待发货</span>
                        </div>
                        <div class="entry-item-box" @click="goOrderListPage(2)">
                            <img class="icon-order" src="../assets/images/me/icon_order_status_dsh.png">
                            <span>待收货</span>
                        </div>
                        <div class="entry-item-box" @click="goOrderListPage(3)">
                            <img class="icon-order" src="../assets/images/me/icon_order_status_ywc.png">
                            <span>已完成</span>
                        </div>
                        <div class="entry-item-box" @click="goAfterOrderListPage()">
                            <img class="icon-order" src="../assets/images/me/icon_order_status_thh.png">
                            <span>退换货</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="big-btn-entry-box">
                <div class="entry-item" @click="goPageByPath('/foster/orderList')"><img
                        src="../assets/images/me/foster_order_entry.png"></div>
                <div class="entry-item" @click="goPageByPath('/myCommissionSaleList')"><img src="../assets/images/me/commission_sale_entry.png"></div>
            </div>
            <div class="service-tools-entry box-shadow">
                <div class="title-box">
                    <span>服务与工具</span>
                </div>
                <div class="entry-list-box flex-flow">
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/incomePick')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_pomelo_tree2.png">
                        <span>每日摘果</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/me/earnings')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_share.png">
                        <span>收益中心</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/me/userWallet')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_wallet.png">
                        <span>我的钱包</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/me/brokerage')">
                        <img class="icon-service-tools" style="width: 52px;" src="../assets/images/me/icon_brokerage.png">
                        <span>我的佣金</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/sharePoster')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_person.png">
                        <span>邀请好友</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/shopping/addressList')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_address.png">
                        <span>收货地址</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="getCustomerServiceInfo()">
                        <img class="icon-service-tools" src="../assets/images/me/icon_service.png">
                        <span>联系客服</span>
                    </div>
                    <div class="entry-item-box fixed-size" @click="goPageByPath('/foster/luckyDraw')">
                        <img class="icon-service-tools" src="../assets/images/me/icon_gife.png">
                        <span>每日抽奖</span>
                    </div>
                  <div class="entry-item-box fixed-size"
                       v-if="userInfo.financeAdmin"
                       @click="goPageByPath('/withdrawListAdmin')">
                    <img class="icon-service-tools other-size" src="../assets/images/me/icon_txjl.png">
                    <span>提现管理</span>
                  </div>
                  <div class="entry-item-box fixed-size"
                       v-if="userInfo.financeAdmin"
                       @click="goPageByPath('/orderManageAdmin')">
                    <img class="icon-service-tools other-size" src="../assets/images/me/icon_ddgl.png">
                    <span>订单管理</span>
                  </div>
                  <div class="entry-item-box fixed-size"
                       v-if="userInfo.financeAdmin"
                       @click="goPageByPath('/me/dataStatistics')">
                    <img class="icon-service-tools other-size" src="../assets/images/me/icon_ddgl.png">
                    <span>数据统计</span>
                  </div>
                </div>
            </div>
            <div class="placeholder-box"></div>
<!--            <div class="company-name-box"><span>技术支持&copy;河源市泉眼科技有限公司</span></div>-->
          <div class="company-name-box"><span>技术支持&copy;紫金县乡愁生态农业有限公司</span></div>
            <div class="placeholder-box"></div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { setUserInfo, removeToken } from "@/api/token";
import {
  getUserIncomeTotal,
} from "@/api/earnings";
import { getUserInfo, getCurUserIntegral } from "@/api/user";
import { showConfirmDialog, showSuccessToast } from "vant";
import Big from "big.js";
import { useKeepAlive } from "@/hooks/keepAliveRouter";
import { getCustomerService } from "@/api/mall/product";
import useClipboard from "vue-clipboard3";
const router = useRouter();
const route = useRoute();
const { toClipboard } = useClipboard();
// useKeepAlive("Me");
const userInfoData = ref({});
const agreementFlg = ref(1);
const userIntegral = ref(0);
let available = ref(0)

const showAuthenticationDialog = function () {
    if (agreementFlg.value === 0) {
        showConfirmDialog({
            message: "当前账号未实名认证",
            confirmButtonText: "去认证",
        }).then(() => {
            toAuthentication();
        });
    }
};

const userInfo = computed(() => {
    return userInfoData.value;
});

const toAuthentication = function () {
    goPageByPath("/authentication")
};

const goOrderListPage = (status) => {
    goPageByPath('/shopping/orderList', { status })
}

const goAfterOrderListPage = ()=>{
    goPageByPath("/shopping/afterSaleOrderList")
}

const goPageByPath = (path = '', query = {}) => {
    router.push({ path, query })
}

const getCustomerServiceInfo = () => {
  getCustomerService().then((res) => {
    // console.log(res);
    showConfirmDialog(
      {message: '客服热线: ' + res.data.strVal,
        cancelButtonText: '复制热线电话',
      }
    ).catch(async () => {
      // on cancel
      try {
        await toClipboard(res.data.strVal);
        showSuccessToast("复制成功");
      } catch (e) {
        console.error(e);
      }
    });
  })
}

onBeforeMount(() => {
    getUserInfo().then((res) => {
        const data = res.data || {};
        userInfoData.value = data;
        setUserInfo(data);
        agreementFlg.value = data.agreementFlg;
        showAuthenticationDialog();
    });

    getCurUserIntegral().then(({ data = 0 }) => {
        userIntegral.value = data;
    });

    getUserIncomeTotal().then((res) => {
    const data = res.data || {};
    let income = data.income || 0;
    let levIncome = data.levIncome || 0;
    let outcome = data.outcome || 0;
    let subIncome = data.subIncome || 0;
    // let totalIncome = Big(income)
    //   .plus(levIncome)
    //   .plus(subIncome);
    // available.value = Big(totalIncome).minus(outcome);
    let pickIncome = data.pickIncome || 0;
    available.value = pickIncome + subIncome + levIncome - outcome;
  });
});

</script>

<style lang="scss">
.me-page {
    min-height: calc(100vh - 100px);
    background: #F5F6F8;
    position: relative;
    // padding-bottom: 100px;

    .me-content-container {
        width: 100%;

        .bg-box {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 410px;
            background: url("../assets/images/me/bg_me_top_2.png") left no-repeat;
            background-size: 100% 410px;
            opacity: 0.53;
        }
    }

    .me-top-container {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        width: 100%;
        position: relative;

        .user-personal-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            padding: 27px 30px 27px 60px;

            .avatar-box {
                width: 126px;
                height: 126px;
                margin-right: 14px;

                .avatar {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .username-box {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name {
                    font-family: PingFangSC-Medium;
                    font-size: 32px;
                    font-weight: normal;
                    color: #121924;
                    margin-bottom: 10px;
                }

                .phone {
                    font-family: HelveticaNeue;
                    font-size: 28px;
                    font-weight: normal;
                    color: #121924;
                }

                .lv {
                    font-family: HelveticaNeue;
                    font-size: 25px;
                    font-weight: normal;
                    color: #181d25;
                    margin-left: 10px;
                }
            }

            .btn-setting {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;

                & img {
                    width: 34px;
                    height: 31px;
                }
            }
        }

        .user-money-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .detail-item-box {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #979797;
                font-family: PingFangSC-Regular;
                font-size: 22px;
                font-weight: normal;
                box-sizing: border-box;
                padding: 20px 0px;

                & span:nth-child(1) {
                    color: #121924;
                    margin-bottom: 4px;
                }

                & span:nth-child(2) {
                    // color: #57575A;
                    color: #121924;
                }

                & span.earnings-color {
                    color: #FF6700;
                }

                & .big-bum {
                    font-size: 36px;
                }
            }
        }


    }

    .user-order-entry,
    .service-tools-entry {
        width: 712px;
        height: auto;
        border-radius: 10px;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 15px;
        background-color: #FFFFFF;
        margin-top: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.box-shadow {
            box-shadow: 0px 2px 10px 3px rgba(230, 230, 230, 0.4);
        }

        .title-box {
            width: 100%;
            height: 75px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-family: PingFangSC-Medium;
            font-weight: normal;

            & span:nth-child(1) {
                font-size: 32px;
                color: #121924;
            }

            & span:nth-child(2) {
                font-size: 26px;
                color: #A3A3A3;
            }
        }

        .entry-list-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            // margin: 30px 0px;

            &.flex-flow {
                flex-wrap: wrap;
            }

            .entry-item-box {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 30px 0px;

                &.fixed-size {
                    flex: none;
                    width: 25%;
                }


                .icon-order {
                    width: 44px;
                    height: 37px;
                    margin-bottom: 22px;
                }

                .icon-service-tools {
                    width: 40px;
                    height: auto;
                    margin-bottom: 22px;
                    &.other-size{
                        width: 47px;
                    }
                }

                & span {
                    font-family: PingFangSC-Regular;
                    font-size: 26px;
                    font-weight: normal;
                    color: #121924;
                }
            }
        }


    }

    // .service-tools-entry{
    //     margin-bottom: 30px;
    // }
    .company-name-box{
        width: 100%;
        text-align: center;
        font-size: 24px;
    }


    .big-btn-entry-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 21px;

        .entry-item {
            flex: 1;
            height: 124px;
            margin: 19px;

            & img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
