<template>
  <div class="main van-safe-area-top">
    <router-view v-slot="{ Component }">
      <keep-alive :max="15" :include="keepAliveInclude">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <div v-if="showTabbar" class="van-safe-area-bottom">
    <van-tabbar route placeholder>
      <van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/myFosterProduct" icon="flower-o">
        认养
      </van-tabbar-item>
      <!-- after-sale -->
      <van-tabbar-item replace to="/myCommissionSaleList" icon="cash-back-record">寄售</van-tabbar-item>
      <van-tabbar-item replace to="/me" icon="user-o"> 我的 </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, watchEffect } from "vue";
import { keepAliveInclude } from "@/hooks/keepAliveRouter";
const route = useRoute();
const showTabbar = ref(false);
watchEffect(() => {
  showTabbar.value = [
    "/home",
    "/myFosterProduct",
    "/me",
    "/myCommissionSaleList",
  ].includes(route.path);
  window.isFirst = ["/", "/login"].includes(route.path) || showTabbar.value;
});
</script>

<style>
html,
body {
  max-width: 100vw;
  height: 100%;

}

.main {
  word-wrap: break-word;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.van-card__title {
  font-size: 14px;
}

.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.van-nav-bar--fixed,
.van-tabbar--fixed {
  /* position: sticky !important;
  top: 0px; */
  z-index: 99 !important;
}

.van-cell__title{
  font-size: 30px !important;
}
.van-cell__value{
  font-size: 26px !important;
}

.placeholder-box {
  width: 100%;
  height: 50px;
}
</style>
