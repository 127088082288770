import { http } from "@/api/request.js";

/**
 * 获取用户寄售商品列表
 * @param {Object} params
 * @returns
 */
export function getConsignmentSaleList(params) {
  return http.get("/sqsm/consignment-sales/page", { params });
}
