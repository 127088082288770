import { http } from "./request";

/**
 * 获取首页认养商品列表
 * @param {Object} params
 * @returns
 */
export const getFosterProducts = function (params) {
  return http.get("/pomelo/product/pagePub", { params });
};

/**
 * 获取首页商品详情
 * @param {Object} params
 * @returns
 */
export const getProductDetails = function (params) {
  return http.get("/pomelo/product/get", { params });
};

/**
 * 用户下单
 * @param {Object} params
 * @returns
 */
export const buyProduct = function (params) {
  return http.post("/pomelo/product/shopProduct", params);
};

/**
 * 获取自己的产品
 * @param {Object} params
 * @returns
 */
export const getOwnProductList = function (params) {
  return http.get("/pomelo/my-product/userPage", { params });
};

/**
 * 获取用户柚子树总数
 * @param {Object} params
 * @returns
 */
export const getMyProNum = function (params) {
  return http.get("/pomelo/my-product/myProNum", { params });
};

/**
 * 获取非认养产品列表
 * @param {Object} params
 * @returns
 */
export const getNotFosterProducts = function (params) {
  return http.get("/sqsm/product/pub/page", { params });
};
