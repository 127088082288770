import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import { installVant } from "@/common/importVant";
// import { installOtherComponents } from "@/common/importOtherComponent";
import { initData } from "@/common/initdata";
import { initOnBackbutton } from "@/common/plus.js";

// 调试工具
// import eruda from "eruda";
// eruda.init();

initOnBackbutton();
initData().then(() => {
  const app = createApp(App);
  app.use(router);
  app.config.globalProperties.$plusExtends = new Promise((resolve) => {
    if (window.plus) {
      resolve()
    } else {
      document.addEventListener('plusready', resolve, false)
    }
  })
  // app.config.globalProperties.$plusExtends = fn => {
  //   if (window.plus) {
  //     setTimeout(fn, 0)
  //   } else {
  //     document.addEventListener('plusready', fn, false)
  //   }
  // }
  installVant(app);
  // installOtherComponents(app);
  app.mount("#app");
});

// 监听未处理的Promise错误
window.addEventListener(
  "unhandledrejection",
  (err) => {
    console.log(err.reason);
    err.preventDefault();
  },
  false
);

// window.addEventListener("error", (error) => {
//   alert(error);
// });
