import {
  Button,
  Cell,
  List,
  Tabbar,
  TabbarItem,
  NavBar,
  PullRefresh,
  Card,
  Tab,
  Tabs,
  Tag,
  Empty,
  Swipe,
  SwipeItem,
  NoticeBar,
  Icon,
  Form,
  Field,
  CellGroup,
  Toast,
  Dialog,
  SubmitBar,
  Popup,
  Image as VanImage,
  Divider,
  Stepper,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Sticky,
  Space,
  Picker,
  ActionSheet,
  SwipeCell,
  Uploader,
  PasswordInput,
  NumberKeyboard,
  Search,
  DropdownMenu,
  DropdownItem,
  AddressEdit,
  AddressList,
  CouponList,
  Skeleton,
  SkeletonImage,
  SkeletonParagraph,
  Area,
  Rate,
  CountDown,
  Progress,
  Checkbox,
  CheckboxGroup,
} from "vant";
import "vant/lib/index.css";

export const installVant = function (app) {
  app.use(Button);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(NavBar);
  app.use(PullRefresh);
  app.use(List);
  app.use(Cell);
  app.use(Card);
  app.use(Tag);
  app.use(Empty);
  app.use(Swipe);
  app.use(SwipeItem);
  app.use(NoticeBar);
  app.use(Tab);
  app.use(Tabs);
  app.use(Icon);
  app.use(Form);
  app.use(Field);
  app.use(CellGroup);
  app.use(Toast);
  app.use(Dialog);
  app.use(SubmitBar);
  app.use(Popup);
  app.use(VanImage);
  app.use(Divider);
  app.use(Stepper);
  app.use(ActionBar);
  app.use(ActionBarIcon);
  app.use(ActionBarButton);
  app.use(Sticky);
  app.use(Space);
  app.use(Picker);
  app.use(ActionSheet);
  app.use(SwipeCell);
  app.use(Uploader);
  app.use(NumberKeyboard);
  app.use(PasswordInput);
  app.use(Search);
  app.use(DropdownMenu);
  app.use(DropdownItem);
  app.use(AddressEdit);
  app.use(AddressList);
  app.use(CouponList);
  app.use(Skeleton);
  app.use(SkeletonImage);
  app.use(SkeletonParagraph);
  app.use(Area);
  app.use(Rate);
  app.use(CountDown);
  app.use(Progress);
  app.use(Checkbox);
  app.use(CheckboxGroup);
};
